.chart {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .legendIcon {
        // width: 40px;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0 30px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .activeLegend {
        background-color: rgba(14, 138, 63, 0.0784313725);

        svg {
            fill: green;
        }
    }

}

.resultsMultiple {
    // max-height: calc(100vh - 490px);
    // overflow-y: auto;
}

.select {
    height: 25px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--gray-border);
    color: var(--gray-text);
    font-size: 12px!important;
}

.select:focus {
    outline: none;
    border: 2px solid var(--green);
}