.detail {
  width: 100%;
  margin-left: 10px;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  // margin-left: 18px;

  .key {
    width: 40%;
  }
  
  .value {
    font-weight: 600;
  }
}
