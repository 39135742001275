.div {
  width: 100%;
  color: #000;
  border-bottom: 1px solid var(--gray-border);
  padding: 16px;
  font-size: 12px;
  overflow: hidden;
  font-weight: bold;
  font-style: normal;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, Noto Sans JP;
  letter-spacing: 0.25px;

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-left: 11px;
  }

  .body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      min-width: 25%;
      font-size: 13px;
      line-height: 24.5px;
    }

    .right {
      color: var(--green);
      cursor: pointer;
      margin-top: 2px;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.0025em;

      .loading {
        height: 100%;
        display: flex;

        .text {
          padding-left: 11px;
          font-weight: 500;
          color: black;
        }
      }

      .resp {
        color: grey;
        font-weight: 500;
      }
    }
  }

  .boldtext {
    min-width: 25%;
    font-size: 13px;
    line-height: 24.5px;
    letter-spacing: 0.25px;
  }
}
