
.singleHazardInfo {
  position: absolute;
  height: 24px;
  width: 35px;
  right: 20px;
  overflow-y: visible;
  cursor: pointer;
}

.resultsSingle {
  // max-height: calc(100vh - 425px);
  // overflow-y: auto;
  padding: 0.75rem;
}

.resultsMultiple {
  // height: calc(100vh - 445px);
  // overflow-y: auto;
  padding: 0.75rem;
}