.legendItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.lagend {
    width: 100%;
    height: 5px;
    border: 0px solid grey;
}

.active {
    height: 10px;
    box-shadow: 0px 1px 1px grey;
}

.tooltip::before {
    overflow: hidden;
    text-align: start;
    white-space: wrap;
}

.analysisItems {
    border-bottom: solid 1px #cccccc;
    padding: 14px 16px 8px;
}

.riskScore {
    border-radius: 4px;
    border: 1px solid rgba(33, 33, 33, .12);
    margin-left: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;
    padding-bottom: 5px;

    >div {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: #0009;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;

        i {
            margin-left: 5px;
            font-size: 20px;
        }

        span {
            margin-left: 15px;
        }
    }
}