.div {
  padding-top: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  .label{
    font-family: Roboto, Noto Sans JP;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: .25px;
  }
  .icon {
    height: 24px;
    width: 24px;
  }
}
