.title {
  margin-top: 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.2px !important;
  color: #000000de !important;
}

.content {
  font-weight: 500;
  font-size: 13px !important;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.033px;
  color: black !important;
  margin-bottom: 10px !important;
}

.item {
  display: flex;
  padding: 6px;
  width: 100%;
  height: 50px;
  align-items: center;
  color: var(--green);
  background-color: #e5f5e9;
  border-radius: 4px;
  margin-bottom: 16px;
  font-weight: 600px;
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-color: #007934;
}

.premium {
  height: 16px;
  width: 16px;
  margin-left: auto;
  margin-right: 10px;
}

.check{
  display: flex;
}