.expander {
  display: flex;
  flex-flow: column;
  padding: 0 8px 0 16px;
  margin: 0 -16px 0 -24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  font-size: 14px;
  cursor: pointer;
}

.icon {
  height: 24px;
  width: 24px;
  margin: 1px 0 0 4px;
}

.result {
  margin-left: auto;
  margin-right: 16px;
}

.children {
  margin: -10px 0 10px;
}