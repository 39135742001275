.layers {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid var(--gray-border);

  .header {
    display: flex;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      fill: var(--gray-text);
    }

    .count {
      margin-left: auto;
      margin-right: 16px;
      font-size: 14px;
      color: var(--gray-border)
    }
  }

  .risk {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    background-color: rgb(233, 140, 24);

  }

  .content {
    padding: 8px 0;
  }
}