.summary {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
  // margin-left: 18px;
}

.btn {
  margin-top: 6px;
}
