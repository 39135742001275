.title {
  margin-top: 10px !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.2px;
  color: #000000de;
}

.content {
  font-weight: 500;
  font-size: 13px !important;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.033px;
  color: grey;

  span {
    text-decoration: underline;
    font-weight: bold;
    color: var(--green);
  }
}

.list {
  list-style: none;
  padding-left: 20px;

  li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: "•";
      position: absolute;
      left: -20px;
      color: black;
    }
  }
}
