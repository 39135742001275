.chart {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    // overflow: hidden;

    .legendIcon {
        // width: 40px;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0 30px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .activeLegend {
        background-color: rgba(14, 138, 63, 0.0784313725);

        svg {
            fill: green;
        }
    }

}

.locationInfo {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: var(--gray-text);
    padding-bottom: 15px;
    border-bottom: 1px solid var(--gray-border);
}