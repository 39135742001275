.legendItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.lagend {
  width: 100%;
  height: 5px;
  border: 0px solid grey;
}

.active {
  height: 10px;
  box-shadow: 0px 1px 1px grey;
}

.value {
  color: var(--On-Surface-87---High-Emphasis, rgba(0, 0, 0, 0.87));
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: 0.033px;
  font-weight: 600;
}
